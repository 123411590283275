//import { wrapper } from "@/store/store";
import React, { useCallback, useEffect, useState, useRef, useLayoutEffect } from "react";
import instagramIcon from "../assets/images/instagram_2.png";
import facebookIcon from "../assets/images/facebook.png";
import pinterestIcon from "../assets/images/pinterest.png";
import tiktokIcon from "../assets/images/tiktok.png";
import youtubeIcon from "../assets/images/youtube.png";
import heartIcon from "../assets/images/heart.svg";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box, Button, TextField, Grid, useMediaQuery } from "@mui/material";
import Layer from "@/components/layer";
import suppliersLogos from '../assets/images/suppliers_logos.png';
import suppliersLogosMobile from '../assets/images/suppliers_logos_mobile.png';
import designerImage from '../assets/images/designer_image.png';
import bannerImage from '../assets/images/LP_Image_R1_for_Video_banner.png';
import bannerImageMweb from '../assets/images/home_carousel_mweb.jpg';
import Alert from '@mui/material/Alert';
import Campaign from "@/store/campaign";
import Image from 'next/image';
import RoomSelectorLayerButton from "@/components/popups/RoomSelectoLayerButton";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { getPageInsertsWithCache, getHeaderDataWithCache, getStaticBasics } from "@/utils/getStaticBasics";
import cssStyles from "./newlandingpage.module.scss"
import discoverStyle from "../assets/images/discover_your_style.jpg";
import discoverStyleMweb from "../assets/images/discover_your_style_mweb.png";
import viewInRoom from "../assets/images/view_room_designs.jpg";
import viewInRoomMweb from "../assets/images/view_room_designs_mweb.png";
import homeOwnerProblem from "../assets/images/homeowner_problem.png";
import playButton from "../assets/images/play_btn.png";
import useScreenSize from "@/utils/useScreenSize";
import { getRoomTypeList } from "@/store/commonSlice";
import Carousel from "react-multi-carousel";
import { gtmClickTracking } from "@/utils";
import { useSwipeable } from "react-swipeable";
import personalAssitantImage from '../assets/images/personal assistance 1.png'
import image1 from '../assets/images/image 1010.png'
import image2 from '../assets/images/7 1.png'
import image3 from '../assets/images/image 1012.png'
import image4 from '../assets/images/9 1.png'
import image5 from '../assets/images/asrid-osamu-modern_250@2x.png'
import image6 from '../assets/images/furniture illustrations 1.png'
import image7 from '../assets/images/furniture illustrations 3.png'
import image8 from '../assets/images/furniture illustrations 4.png'
import image9 from '../assets/images/furniture illustrations 5.png'
import image10 from '../assets/images/furniture illustrations 6.png'
import image11 from '../assets/images/furniture illustrations 7.png'
import image12 from "../assets/images/image 1022.png";
import image13 from '../assets/images/image 1016.png'
import image14 from '../assets/images/image 1017.png'
import image15 from '../assets/images/image 1024.png'
import image16 from '../assets/images/image 1025.png'
import image17 from '../assets/images/image 1026.png'
import image18 from '../assets/images/image 1023.png'
import image19 from '../assets/images/image 1020.png'
import image20 from '../assets/images/image 1021.png'


import HeroImage from "@/components/new_hero_section";
import { Padding } from "@mui/icons-material";
import { useRouter } from "next/router";
import DesignerCarousel from "@/components/new_hero_section/DesignerCarousel";
const currentViewModeConstants = {
    getStartView: 'getStartView',
    videoView: 'videoView'
}
const CustomDot = ({ onClick, ...rest }: any) => {
    const { index, active } = rest;
    return (
        <li>
            <button
                style={{
                    width: '12px',
                    height: '12px',
                    "borderRadius": "50%",
                    opacity: 1,
                    backgroundColor: active ? '#080808' : '',
                    transition: 'background .5s',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: 'grey',
                    padding: 0,
                    margin: 0,
                    marginRight: '6px',
                    outline: 0,
                    cursor: "pointer"
                }}
                onClick={() => {
                    console.log(`Dot ${index + 1} clicked`);
                    onClick();

                    if (index + 1 == 1) {
                        gtmClickTracking({ event: 'Homepage_plays_video', id: "Homepage_plays_video" });
                    } else if (index + 1 == 2) {
                        gtmClickTracking({ event: "view_slide", id: "view_slide", value: "style" })
                    } else if (index + 1 == 3) {
                        gtmClickTracking({ event: "view_slide", id: "view_slide", value: "room" })
                    } else if (index + 1 == 4) {
                        gtmClickTracking({ event: "view_slide", id: "view_slide", value: "browse" })
                    }
                }}
            />
        </li>
    );
};

const Welcome = (props: any) => {

    const {
        env,
        roomTypes,
    } = typeof props.message == "string" ? JSON.parse(props.message) : props.message;
    const [canAutoPlay, setCanAutoPlay] = useState(true);
    const [showVideo, setShowVideo] = useState(false);

    const [overlayOpen, setOverlayOpen] = useState(false)
    const [currentView, setCurrentView] = useState(currentViewModeConstants.getStartView)
    const isMobile = useMediaQuery("(max-width:600px)");
    const router = useRouter()

    const searchZip = useCallback(() => {
        // $('#zip-error').show()
    }, []);

    const toogleZipSearch = useCallback(() => {
        const zipSearchModalElem: any = document.getElementById("zip-search");
        zipSearchModalElem.modal('toggle');
        //$('#zip-search').modal('toggle');
        //$('#zip-error').hide();
        //("#zip").val("");
    }, []);

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const [openSearchZipCodeLayer, setOpenSearchZipCodeLayer] = useState(false);
    const handleSearchZipCodeLayerOpen = () => {
        gtmClickTracking({
            event:'find_a_designer'
        })
        setOpenSearchZipCodeLayer(true);
    };
    const handleSearchZipCodeLayerClose = () => {
        setOpenSearchZipCodeLayer(false);
    };
    const videoRef = useRef<HTMLVideoElement>(null);
    const screenSize = useScreenSize();
    const carouselRef = useRef<any>();

    const responsiveData = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 767, min: 601 },
            slidesToSlide: 1,
            items: 1
        },
        mobile: {
            breakpoint: { max: 600, min: 320 },
            slidesToSlide: 1,
            items: 1
        }
    };

    const imageLoaded = () => {
        if (videoRef.current) {
            console.log('Loading video');

            gtmClickTracking({ event: 'Homepage_plays_video' });
            const source = document.createElement('source');
            source.src = screenSize.width > 768 ? "https://d221a61rb87vel.cloudfront.net/Videos/catalog-assets/LP_Video_V8.mp4" : "https://d221a61rb87vel.cloudfront.net/Videos/catalog-assets/m_LP_Video_V8.mp4";
            source.type = "video/mp4";
            videoRef.current.appendChild(source);
            videoRef.current.load();
            const bannerContainer: HTMLElement | null = document.getElementById('banner-container');
            if (bannerContainer) {
                bannerContainer.classList.add("video-loaded");
            }
        }
    }

    useEffect(() => {
        imageLoaded();
    }, [showVideo]);

    const handlers = useSwipeable({
        onSwipedLeft: () => { console.log('Swiped Left!'); setCanAutoPlay(false); },
        onSwipedRight: () => { console.log('Swiped Right!'); setCanAutoPlay(false); },
        onSwipedUp: () => { console.log('Swiped Up!'); setCanAutoPlay(false); },
        onSwipedDown: () => { console.log('Swiped Down!'); setCanAutoPlay(false); },
        trackMouse: true, // Track mouse events as well
    });
    const secondSectionItems = [
        {
            id: 1,
            image:
                image1.src,
            title: "Inspire",
            description: "Think of us as your personal, digital design assistant!",
        },
        {
            id: 2,
            image:
                image2.src,
            title: "Design",
            description:
                "Design like a pro with AI-powered personalized recommendations",
        },
        {
            id: 3,
            image:
                image3.src,
            title: "Visualize",
            description:
                "Create accurate, 3D virtual room plans with our easy to use app",
        },
        {
            id: 4,
            image:
                image4.src,
            title: "Realize",
            description:
                "Fastest, easiest, most affordable way to elevate your space.",
            text: "FREE shipping with every order",
        },
    ];
    const thirdSectionItems = [
        {
            id: 1,
            title: "Visualize",
            subtitle: "before you buy",
        },
        {
            id: 2,
            title: "Confidence",
            subtitle: "with every purchase",
        },
        {
            id: 3,
            title: "High quality",
            subtitle: "products from top brands",
        },
        {
            id: 4,
            title: "FREE shipping",
            subtitle: "on every order",
        },
    ];
    const fourthSectionItems = [
        {
            id: 1,
            image: image13.src,
            text: "Style Quiz",
            title: "Start with a style.",
            description:
                "Get personalized recommendations by completing your style profile",
            pageLink: '/stylequiz',
            trackingObj: { event: "discover_style_ways"}
        },
        {
            id: 2,
            image: image5.src,
            title: "Have a room in mind?",
            text: "Select a room",
            description: "Start with a room and design your perfect space.",
            pageLink: '/roomselection',
            trackingObj: { event: "discover_room_ways"}
        },
        {
            id: 3,
            image:
                image14.src,
            title: "Not sure where to start?",
            text: "Browse ideas",
            description: "Browse ideas and get inspired.",
            pageLink: '/inspirations',
            trackingObj: { event: "browse_inspiration_ways"}
        },
    ];
    const testimonialsArr = [
        {
            id: 1,
            profileImage:
                image15.src,
            review: "The visual designer is a game changer!",
            customerName: "PEDRO",
            city: "MIAMI, FL",
        },
        {
            id: 2,
            profileImage:
                image16.src,
            review:
                "We loved how intuitive the visual designer was! We designed our room in just a few minutes!.",
            customerName: "JR & RACHEL",
            city: "MIAMI, FL",
        },
        {
            id: 3,
            profileImage:
                image17.src,
            review: "The style quiz was such fun! The style vibe matches my taste!",
            customerName: "SAKETH",
            city: "LOS ANGELES, CA",
        },
    ];
    function handleCallGetStarted() {
        gtmClickTracking({
            event: 'get_started'
        })
        setOverlayOpen(true)
        setCurrentView(currentViewModeConstants.getStartView)
    }
    return (
        <>
            <main className={"home-container"}>
                <Campaign campaignName="landing" pageName="landing" />

                <section {...handlers} className={"mobile-slider"} style={{ 'padding': '0px' }} onClick={() => { setCanAutoPlay(false); }}>
                    <HeroImage overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} currentView={currentView} setCurrentView={setCurrentView} />
                </section>
                <section className="landing-page-second-card" style={{ padding: '10px' }}>
                    <hr className={cssStyles.landingPageSecondSectionLine} />
                    <h1 className={cssStyles.landingPageSecondHeader}>
                        How we empower you <br />
                        <span className={cssStyles.landingPageSecondHeaderSpan}> to bring your dream home into reality</span>
                    </h1>
                    <hr className={cssStyles.landingPageSecondSectionLine} />
                    <div className={cssStyles.landingPageSecondSection}>
                        {
                            secondSectionItems.map((eachItem: any, index: number) => {
                                const isEven = index % 2 === 0;
                                return <div className={cssStyles.landingPageSecondSectionFlexChild} key={eachItem?.id}>
                                    {isEven || isMobile ? <>
                                        <img src={eachItem?.image} alt={eachItem?.title} width={206} height={192} />
                                        <h3>{eachItem?.title}</h3>
                                        <p>{eachItem.description}</p>
                                        <span style={{ display: 'block' }}>{eachItem?.text}</span>
                                        {(isMobile && secondSectionItems.length - 1 !== index) && <hr className={cssStyles.landingPageSecondSectionLine} />}

                                    </> : <>

                                        <h3>{eachItem?.title}</h3>
                                        <p>{eachItem.description}</p>
                                        <span style={{ display: 'block' }}>{eachItem?.text}</span>
                                        <img src={eachItem?.image} alt={eachItem?.title} width={206} height={192} />
                                    </>}
                                </div>
                            })
                        }
                    </div>
                </section>

                <section className="landing-page-third-card" style={{ paddingTop: 0, marginBottom: '30px',paddingLeft:'15px',paddingRight:'15px' }}>
                    <div className={cssStyles.featuresBackgroundContainer}>
                        <h2 className={cssStyles.featuresHeading}>
                            Bring your <em>dream</em> home to life
                        </h2>
                        <div className={cssStyles.featuresContainer}>
                            {/* <div className={cssStyles.featuresGrid}> */}
                            {thirdSectionItems.map((feature: any, index: number) => (
                                <div className={cssStyles.featureItem} key={index}>
                                    <div className={cssStyles.featureCircle}>
                                        <h3>{feature.title}</h3>
                                        <p>{feature.subtitle}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* </div> */}
                    </div>
                </section>

                <section className="landing-page-fourth-card" style={{ paddingTop: 0, marginBottom: '20px' }}>
                    <div className={cssStyles.landingPageFourthsectionHeaderContainer}>
                        <h1 className={cssStyles.landingPageFourthsectionHeader}>Ways to get started <br />
                            <span> Fastest, easiest, most affordable way to elevate your space </span></h1>
                    </div>
                    <div className={cssStyles.landingPageFourthsection}>
                        {
                            fourthSectionItems.map((eachItem: any) => {
                                return <div key={eachItem?.id} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', flex: 1, rowGap: '20px', width: '269px' }} className={cssStyles.landingPageFourthsectionFlexChild}>

                                    <div style={{ height: '15%' }}>
                                        <h3 className={cssStyles.landingPageFourthsectionTitle}>{eachItem?.title}</h3>
                                    </div>
                                    <div>


                                        <img src={eachItem?.image} alt={eachItem?.title} width={187} height={187} style={{ borderRadius: '50%', objectFit: 'cover' }} className={cssStyles.landingPageFourthsectionImage} />
                                    </div>
                                    <div>


                                        <button onClick={() => {
                                            router.push(eachItem?.pageLink)
                                            gtmClickTracking(eachItem?.trackingObj)
                                        }} className={cssStyles.landingPageFourthsectionButton}>{eachItem?.text}</button>
                                    </div>
                                    <div style={{ height: '15%', overflow: 'hidden' }}>


                                        <p className={cssStyles.landingPageFourthsectionDescription}>{eachItem?.description}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </section>

                <section className="landing-page-fifth-card" style={{ marginBottom: '30px',paddingLeft:'15px',paddingRight:'15px' }}>
                    <div className={cssStyles.landingPageFifthSectionContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                            <div style={{ height: '15%' }}>
                                <p>Stylish <i>essentials</i> for <br /> your room</p>

                            </div>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={image18.src} alt="stylish-tile-image" width={isMobile ? 218 : 178} height={isMobile ? 158 : 133} style={{ objectFit: 'cover' }} className={cssStyles.fifthSectionImageOne} />
                                    {/* <span className={cssStyles.plusIcon}>+</span> */}
                                </div>


                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <span className={cssStyles.plusIcon}>+</span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                            <div style={{ height: '15%' }}>
                                <p><i>Reimagine</i> your space</p>

                            </div>
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={image19.src} alt="reimagine in you space image" width={isMobile ? 254 : 214} height={isMobile ? 158 : 133} style={{ objectFit: 'cover' }} className={cssStyles.fifthSectionImageOne} />
                                </div>


                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <span className={cssStyles.plusIcon}>=</span>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                            <div style={{ height: '15%' }}>
                                <p><i>Enjoy</i> living in your <br /> new space!</p>

                            </div>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <img src={image20.src} alt="enjoy living in your room image" width={isMobile ? 240 : 202} height={isMobile ? 158 : 133} style={{ objectFit: 'cover' }} className={cssStyles.fifthSectionImageOne} />
                                <img src={image12.src} alt="enjoy-living" width={100} height={100} style={{ objectFit: 'cover', position: 'absolute', borderRadius: '50%', right: -20, bottom: -20, border: '6px solid #0D908E' }} />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="landing-page-six-card">
                    <div className={cssStyles.landingPageTestimonialSection}>
                        {
                            isMobile && <div style={{ width: '90%', border: '1px solid #A5A5A5', padding: '20px', marginLeft: '10px', flex: '0 0 90%', maxWidth: '90%' }} className={cssStyles.landingPageTestimonialSectionContainer}>
                                <img src={testimonialsArr[0].profileImage} alt="profile" width={145.47} height={150} className={cssStyles.landingPageTestimonialProfileImage} />
                                <p className={cssStyles.landingPageTestimonial}>&quot;{testimonialsArr[0].review}&quot;</p>
                                <p className={cssStyles.landingPageTestimonialCustomerName}>{testimonialsArr[0].customerName}</p>
                                <p className={cssStyles.landingPageTestimonialCustomerName}>{testimonialsArr[0].city}</p>
                            </div>
                        }
                        {
                            testimonialsArr.slice(isMobile ? 1 : 0,).map((eachTestimonial: any) => <div key={eachTestimonial?.id} style={{ width: isMobile ? '90%' : '412px', border: '1px solid #A5A5A5', padding: '20px', flexShrink: 0 }} className={cssStyles.landingPageTestimonialSectionContainer}>
                                <img src={eachTestimonial?.profileImage} alt="profile-image" width={145.47} height={150} className={cssStyles.landingPageTestimonialProfileImage} />
                                <p className={cssStyles.landingPageTestimonial}>&quot;{eachTestimonial?.review}&quot;</p>
                                <p className={cssStyles.landingPageTestimonialCustomerName}>{eachTestimonial?.customerName}</p>
                                <p className={cssStyles.landingPageTestimonialCustomerName}>{eachTestimonial?.city}</p>
                            </div>
                            )
                        }

                    </div>
                </section>

                <section className="landing-page-seventh-card" style={{ marginTop: '10px' }}>
                    <div className={cssStyles.landingPageSeventhSectionHeaderContainer}>
                        <h1 className={cssStyles.landingPageSeventhSectionHeader}>Ready to bring <i>dream</i>  home into reality?</h1>
                        <button onClick={handleCallGetStarted} className={cssStyles.landingPageSeventhSectionGetStartedButton}>Get Started</button>
                    </div>
                    <div className={cssStyles.landingPageSeventhSection}>
                        <div className={cssStyles.landingPageSeventhLeftSection}>
                            <p className={cssStyles.landingPageSeventhLeftSectionText}>Prefer a human touch? <br /> <i style={{ fontSize: isMobile ? '21px' : '28px' }}>Check out our talented designers </i> </p>
                            {/* <img src="https://s3-alpha-sig.figma.com/img/5e1b/08b5/c23b931bd7bbf0ecd6863de405e06dd1?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=erhlqLa9ApKNnXw8x0sOQOT7OowcWv-JHR~k3GqfAADAKlZHpiEN6v9vKyPFWNCbLFDndM64efZqFgPz5uALuCqjhcOLWpSzlEf9eJ3D7mYRT4QOGDK4egFUrm-fpmmpt4n6FIR5gnsy30aq8u2PXLzu6bZjgkf2SZW~CVih7dr7~aQAtoaBihWKkP~iVSdu7rlkftDnTw5PnHebkWW8K-JzHqOYiqkRZRxNOFTgMjuHwOr6IxAAZBuYlPKIArgXWGV3Ga4c58l2AZBzN-0aIArmbTFdxMQdUlBUIUZGCTDMdN1ogQiIiTygk67elfaAy8HDVg6LucjaSuWim6j33g__" alt="" width={503} height={367}/> */}
                            <img src={personalAssitantImage.src} alt="personalized-section-image" width={377} height={275} />
                            <br />
                            <button className={cssStyles.findADesignerButton} onClick={handleSearchZipCodeLayerOpen}>FIND A DESIGNER</button>
                            {/* <a href="javascript:void(0)" id="find_prof_designer" onClick={handleSearchZipCodeLayerOpen} className={cssStyles.findADesignerButton}>FIND A DESIGNER</a> */}
                        </div>
                        <div className={cssStyles.landingPageSeventhRightSection}>
                            <p className={cssStyles.landingPageSeventhRightSectionText}>Looking for a specific item? <br /> <i style={{ fontSize: isMobile ? '21px' : '28px' }}>Browse our collection from top brands </i></p>
                            <div>
                                <img src={image6.src} alt="sofa" width={120} height={100} style={{ objectFit: 'cover' }} className={cssStyles.landingPageSeventhRightSectionSofaImage} />
                                <img src={image7.src} alt="pillow" width={110} height={100} style={{ objectFit: 'cover' }} />
                                <img src={image8.src} alt="table" width={110} height={100} style={{ objectFit: 'cover' }} />

                            </div>
                            <div>
                                <img src={image9.src} alt="table" width={110} height={100} style={{ objectFit: 'cover' }} className={cssStyles.landingPageSeventhRightSectionSofaImage} />
                                <img src={image10.src} alt="chair" width={110} height={100} style={{ objectFit: 'cover' }} />
                                <img src={image11.src} alt="bed" width={110} height={100} style={{ objectFit: 'cover' }} />

                            </div>
                            <br />
                            <button onClick={() => { 
                                 gtmClickTracking({
                                    event:'shop_now'
                                })
                                router.push('/shop') }} className={cssStyles.findADesignerButton}>SHOP NOW</button>
                        </div>
                    </div>
                </section>
<section>
                    <DesignerCarousel open={openSearchZipCodeLayer}
                        onClose={handleSearchZipCodeLayerClose} />

</section>
                <span id="faq"></span>
                <div className="row-gutter-2"></div>
                <section>
                    <div className="container-spacing">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-3 text-center" style={{ textAlign: 'center' }}>FAQs</h3>
                                <div className="row-gutter-2"></div>
                            </div>
                        </div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ boxShadow: 'none', '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important',
                                    margin: '0 !important'
                                }}
                            ><strong>How is Nestingale different from other design services?</strong></AccordionSummary>
                            <AccordionDetails>Our goal is to empower homeowners to bring their dream home to life, and we
                                provide a one stop shop for homeowners throughout their design journey, from helping you discover your
                                style, to providing design inspiration, we help you reimagine your homes through personalized and
                                curated design recommendations, cutting-edge tools to let you immersively view recommendations in your
                                own spaces, and by helping you find trusted products and service providers to confidently bring your
                                vision to life. While doing all this, we care about delivering an exceptional experience and high
                                satisfaction, without you having to spend a lot of time or money.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
                            sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important',
                                    '.Mui-expanded': {
                                        margin: '10px 0 !important'
                                    }
                                }}
                            ><strong>How much does Nestingale cost?</strong></AccordionSummary>
                            <AccordionDetails>The Nestingale platform, including our app and all of our tools to help you
                                visualize and design your spaces, is 100% free for homeowners.You only pay for products and services
                                that you decide to purchase.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>What devices are compatible with your service?</strong></AccordionSummary>
                            <AccordionDetails>Currently, our services are available through a wide variety of devices, which
                                include desktop computers, laptops, and mobile devices. We recommend using a Chrome browser for the best
                                experience. We also have a mobile app to scan your room, which currently requires a LiDAR (Light
                                Detection and Ranging)  scanner based iPhone Pro or iPad Pro device. (It will work with any Apple Pro device with LiDAR camera technology)</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>Can I talk to an interior designer for advice?</strong></AccordionSummary>
                            <AccordionDetails>Yes, we are onboarding interior designers onto our platform so that they are
                                available for consultations and services on demand, at a very reasonable price.This service is currently
                                available only in select zip codes, but we will continue to add new locations over time.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>Where do you source your furniture and accessories?</strong></AccordionSummary>
                            <AccordionDetails>We have partnered directly with top-of-the-line manufacturers and suppliers to
                                cut out the middleman and provide our customers with direct access to the highest quality products, at
                                the most competitive prices available.</AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ boxShadow: 'none', '&:before': { 'opacity': '1 !important' }, '&.Mui-expanded': { margin: '0px !important' } }}>
                            <AccordionSummary
                                expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    minHeight: 'initial !important'
                                }}
                            ><strong>How does Nestingale make money?</strong></AccordionSummary>
                            <AccordionDetails>Our goal is to help bring your dream home to life and we do not charge
                                homeowners for our service. If you decide to purchase products and services on the platform, we earn a
                                small fee from the manufacturers and suppliers.</AccordionDetails>
                        </Accordion>
                    </div>
                </section>
            </main>
            {/* <Layer
                open={openSearchZipCodeLayer}
                onClose={handleSearchZipCodeLayerClose}
                dialogTitle=""
                Element={SearchZipCode}
                dialogContentClassName={"handle-design-your-roomLayer"}
                maxWidth="xl"
                dialogContentComponentProps="" /> */}


        </>
    )
}

const SearchZipCode = () => {
    const [showZipError, setShowZipError] = useState(false);
    const searchZip = () => {
        setShowZipError(true);
    }

    return (
        <>
            <div className="message" style={{ padding: '0px 12px' }}>
                <Box component={'div'} p={0}>
                    <div style={{ 'marginBottom': '20px' }}><strong>Search Zip</strong></div>
                    {showZipError && <Alert severity="error" sx={{ marginBottom: '20px', }}>We are not operative in this location. Will update soon</Alert>}
                    <div className="d-flex justify-content-center" style={{ "columnGap": "20px" }}>
                        <TextField fullWidth id="fullWidth" />
                        <Button id="search_zip_continue" color="primary" size="small" onClick={(event: any) => searchZip()}>Continue</Button>
                    </div>
                </Box>
            </div>
        </>
    )
}

export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext) => {

    var { env, pageCacheInSec } = await getStaticBasics(context);
    const headerMenuJson = await getHeaderDataWithCache(env, pageCacheInSec);
    const roomTypes = await getRoomTypeList({ env: 'prod' });
    const { pageInserts } = await getPageInsertsWithCache(env, "landing", pageCacheInSec);

    const pagePropObj = {
        env: env,
        roomTypes: roomTypes,
        headerMenuJson: headerMenuJson,
        pageInserts
    };
    const pageProps = JSON.stringify(pagePropObj);

    return {
        props: {
            buildTime: Date().toString(),
            message: pageProps,
            title: `Nestingale - Design Inspiration, High Quality Furniture`,
            description: `Nestingale is the new way to imagine your dream home and bring it to life.  Find beautifully crafted and designed furniture, decorating ideas and design inspiration`,
            dataLayer: {
                pageType: "Welcome Page",
                pageName: "Welcome",
            }
        },
        // Next.js will attempt to re-generate the page: When a request comes in - At most once every 1 hour
        revalidate: pageCacheInSec, // 1 hour nn seconds
    }
}

export default Welcome;