import axiosObj from "./axios";
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getUserInfo } from "@/utils/useUserInfo";
import { getLoggedInEmailAddress } from "@/utils";

const Campaign = ({ campaignName, pageName }: {  campaignName: string, pageName: string }) => {
    const navigate = useRouter();
    useEffect(() => {
        if (navigate.isReady) {
            const queryCampaign = navigate.query.utm_campaign;
            //may be we should use setCampaignName state.. TBD
            campaignName = typeof queryCampaign === 'string' ? queryCampaign : campaignName;
            const medium = navigate.query.utm_medium;
            if (campaignName && medium !== 'email') {
                const showCampaign = async () => {
                    try {
                        const response = await axiosObj.get('/api/email-marketing/campaigns?campaignName=' + campaignName);
                        const campaignData = response.data[0];
                        const loggedInEmail = await getLoggedInEmailAddress();
                        if (campaignData &&
                                campaignData.active &&
                                campaignData.pageNames?.includes(pageName) &&
                                (!campaignData.suppressOnLogin || !loggedInEmail)
                            ) {
                            const userInfo = await getUserInfo();
                            const script = document.createElement('script');
                            script.async = true;
                            script.src = campaignData.script;
                            script.dataset.cid = userInfo?.cId;
                            script.dataset.form = campaignData.form;
                            document.body.appendChild(script);
                        }
                    } catch (error) {
                        console.error('Error fetching campaign:', error);
                    }
                };
                showCampaign();
            }
        }
    }, [navigate]);

    return null;
};

export default Campaign;
